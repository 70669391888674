<template>
  <span
    :class="['info-badge', 'small-text-semi-bold', 'capitalize-first', status]"
  >
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: ["status"],
};
</script>

<style lang="scss" scoped>
.info-badge {
  display: inline-block;
  border-radius: 4px;
  padding: 4px 6px;
  color: white;
  font-size: 14px;
  font-weight: 600;
}
.CREATING,
.UNSEALED,
.DELETING {
  background-color: #231f6d;
  &:after {
    background-position: center;
    margin-left: 4px;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    animation: lds-ring 3s linear infinite;
    transform-origin: center center;
    line-height: 0;
    content: url("/loader-icon.svg");
  }
}
.CREATED {
  background-color: #4b67ff;
}
.READY,
.CREATED {
  background-color: #42b983;
  &:hover {
    background-color: #02a97d;
  }
}
.UNSEALED {
  background-color: #fbb414;
  &:hover {
    background-color: #efab13;
  }
}
.DELETED {
  background-color: #cecece;
}
.FAILED {
  background-color: #ff4243;
  :hover {
    background-color: #e53a3b;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
