<template>
  <div
    class="org-wrapper"
    @keyup.esc="
      showAddEmail = false;
      allowedEmail = '';
      saveError = '';
    "
  >
    <div class="org-content">
      <div class="counters full-width pt-48">
        <counter :count="orgs.length">Organizations</counter>
        <counter :count="totalUsers" >Users</counter>
        <counter :count="totalEnvironments" >Environments</counter>
        <counter :count="totalTrial" >Trial</counter>
        <counter :count="totalProduction" >Production</counter>
      </div>
      <div class="counters full-width pt-16">
        <counter v-if="totalFailed" :count="totalFailed" ><Badge status="FAILED">Failed</Badge></counter>
        <counter v-if="totalCreating" :count="totalCreating" ><Badge status="CREATING">Creating</Badge></counter>
        <counter v-if="totalCreated" :count="totalCreated" ><Badge status="CREATED">Created</Badge></counter>
        <counter v-if="totalReady" :count="totalReady" ><Badge status="READY">Ready</Badge></counter>
        <counter v-if="totalUnsealed" :count="totalUnsealed" ><Badge status="UNSEALED">Unsealed</Badge></counter>
        <counter v-if="totalDeleting" :count="totalDeleting" ><Badge status="DELETING">Deleting</Badge></counter>
        <counter v-if="totalDeleted" :count="totalDeleted" ><Badge status="DELETED">Deleted</Badge></counter>
        <counter v-if="totalKeysNotGenerated" :count="totalKeysNotGenerated" >No Keys</counter>
      </div>
      <div class="full-width pt-48">
        <div class="search-group">
          <input
            class="search body"
            type="text"
            placeholder="Environments, organizations or users"
            v-model="filterString"
          />
          <i class="search-icon"></i>
        </div>
      </div>
      <div class="heading-2 pl-16 pb-8 pt-16">Allowed emails</div>
      <div class="card-1 bg-white full-width mb-48">
        <div class="company-header flex" style="justify-content: space-between">
          Allowed users list
        </div>
        <div class="pl-16 pb-32 pr-16">
          <div class="subtitle pl-16 pt-16 pb-8">Emails</div>
          <table>
            <thead>
              <tr>
                <td>Email</td>
              </tr>
            </thead>
            <tbody class="body">
              <tr v-for="email in allowedEmails" :key="email">
                <td>{{ email }}</td>
              </tr>
              <tr v-show="!showAddEmail">
                <td
                  class="body-bold no-border"
                  style="cursor: pointer"
                  @click="showAddEmail = true"
                >
                  <Button>+ Add new</Button>
                </td>
              </tr>
              <tr v-show="showAddEmail">
                <td
                  class="body-bold flex no-border"
                  style="justify-content: space-between"
                >
                  <div style="width: 70%">
                    <input
                      class="email-input body"
                      type="email"
                      name="allowedEmail"
                      placeholder="alice@example.com"
                      v-model="allowedEmail"
                      required
                    />
                    <div class="error-text small-text">{{ saveError }}</div>
                  </div>
                  <Button
                    style="width: 30%"
                    @click="addEmail"
                    :loading="addEmailLoading"
                    >Save</Button
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="heading-2 pl-16 pb-8 pt-16">Customer list</div>
      <div class="org-header mb-16">
        <div class="">Organizations</div>
        <div class="">Eligible level</div>
      </div>
      <transition-group name="fade" mode="out-in" tag="div">
        <div
          class="card-1 bg-white full-width mb-48"
          v-for="(o, oi) in orgs"
          :key="o.organizationId"
        >
          <div
            class="company-header flex"
            style="justify-content: space-between"
          >
            <div>{{ o.organizationName }}</div>
            <Toggle
              :model-value="o.eligibleLevel === 'PRODUCTION'"
              @update:modelValue="toggleEligibleLevel(o.organizationId, $event)"
            ></Toggle>
          </div>
          <div class="pl-16 pb-16 pr-16">
            <div class="subtitle pl-16 pt-16 pb-8">Users</div>
            <table>
              <thead>
                <tr>
                  <td>Name</td>
                  <td class="align-right">User Id</td>
                </tr>
              </thead>
              <tbody class="body">
                <tr v-for="(owner) in o.owners" :key="owner.userId">
                  <td>{{ owner.name }}</td>
                  <td class="align-right">{{ owner.email }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="pl-16 pb-32 pr-16">
            <div class="subtitle pl-16 pt-16 pb-8">Environments</div>
            <table>
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Status</td>
                  <td>Category</td>
                  <td class="align-right">Created</td>
                  <td class="align-right">Seal Keys</td>
                </tr>
              </thead>

              <tbody class="body">
                <template v-for="(e, ei) in o.environments" :key="e.environmentId">
                <tr @click="toggleExpanded(`${oi}:${ei}`)">
                  <td class="env-name">{{ e.name }}</td>
                  <td>
                    <Badge :status="e.status">{{ e.status }}</Badge>
                  </td>
                  <td class="capitalize-first">{{ e.category }}</td>
                  <td class="align-right" :title="formattedTime(e?.createdAt)">
                    {{ formattedDate(e.createdAt) }}
                  </td>
                  <td class="align-right" :title="formattedTime(e?.sealKeyCreatedAt)">
                    {{ formattedDate(e?.sealKeyCreatedAt) }}
                  </td>
                </tr>
                <tr v-if="isExpanded(`${oi}:${ei}`)">
                  <td colspan="5">
                     <pre>{{e}}</pre>
                  </td>
                </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import useUser from "@/modules/useUser";
import useOrganizations from "@/modules/useOrganizations";
import Badge from "@/components/Badge";
import Toggle from "@/components/Toggle";
import { computed, ref } from "vue";
import { post } from "@/utils";
import Button from "@/components/Button";
import Counter from '../components/Counter'

export default {
  components: { Button, Toggle, Badge, Counter },
  setup() {
    const { userState } = useUser();
    const filterString = ref("");
    const { loading, organizations, toggleEligibleLevel, allowedEmails } =
        useOrganizations();
    const { addEmail, allowedEmail, addEmailLoading, showAddEmail, saveError } = useAllowedEmails(allowedEmails)
    const orgs = computed(() => {
      return organizations.value.filter((o) => {
        return JSON.stringify(o)
            .toLowerCase()
            .includes(filterString.value.toLowerCase());
      });
    });
    const formattedDate = isoDateStr => isoDateStr ? new Date(isoDateStr).toLocaleDateString() : '';
    const formattedTime = isoDateStr => isoDateStr ? new Date(isoDateStr).toLocaleTimeString() : '';
    const expandedRows = ref(new Set());
    const toggleExpanded = i => expandedRows.value.has(i) ? expandedRows.value.delete(i) : expandedRows.value.add(i) ;
    const isExpanded = i => expandedRows.value.has(i)
    const totalEnvironments = computed(() => orgs.value.reduce((length, org) => length + org.environments?.length, 0));
    const totalUsers = computed(() => orgs.value.reduce((length, org) => length + org.owners?.length, 0));
    const totalKeysNotGenerated = computed(() => orgs.value.reduce((length, org) => length + org.environments?.filter(env => !env.sealKeyCreatedAt).length, 0));
    const totalStatusCount = status => orgs.value.reduce((length, org) => length + org.environments?.filter(env => env.status === status).length, 0);
    const totalFailed = computed(() => totalStatusCount('FAILED'));
    const totalCreating = computed(() => totalStatusCount('CREATING'));
    const totalCreated = computed(() => totalStatusCount('CREATED'));
    const totalReady = computed(() => totalStatusCount('READY'));
    const totalUnsealed = computed(() => totalStatusCount('UNSEALED'));
    const totalDeleting = computed(() => totalStatusCount('DELETING'));
    const totalDeleted = computed(() => totalStatusCount('DELETED'));
    const totalCategoryCount = category => orgs.value.reduce((length, org) => length + org.environments?.filter(env => env.category === category).length, 0);
    const totalTrial = computed(() => totalCategoryCount('TRIAL'));
    const totalProduction = computed(() => totalCategoryCount('PRODUCTION'));
    return {
      profile: userState?.profile,
      loading,
      organizations,
      toggleEligibleLevel,
      filterString,
      orgs,
      allowedEmails,
      allowedEmail,
      addEmail,
      addEmailLoading,
      saveError,
      formattedDate,
      formattedTime,
      toggleExpanded,
      isExpanded,
      expandedRows,
      showAddEmail,
      totalEnvironments,
      totalUsers,
      totalFailed,
      totalCreating,
      totalCreated,
      totalReady,
      totalUnsealed,
      totalDeleting,
      totalDeleted,
      totalTrial,
      totalProduction,
      totalKeysNotGenerated
    };
  }
};

const useAllowedEmails = (allowedEmails) => {

  const allowedEmail = ref("");
  const showAddEmail = ref(false);
  const addEmailLoading = ref(false);
  const saveError = ref("");

  function addEmail() {
    addEmailLoading.value = true;
    saveError.value = "";
    post(
        "/cloud-platform-admin-api/allowed-emails/command/add-allowed-email",
        { allowedEmail: allowedEmail.value.toLowerCase() }
    )
        .then((res) => {
          if (res.status === 204) {
            allowedEmails?.value.push(allowedEmail.value);
            allowedEmail.value = "";
            showAddEmail.value = false;
          } else {
            res
                .json()
                .then((result) => (saveError.value = result?.errorMessages[0]));
          }
        })
        .finally(() => {
          addEmailLoading.value = false;
        });
  }
  return {
    addEmail,
    allowedEmail,
    addEmailLoading,
    saveError,
    showAddEmail
  }
}
</script>

<style lang="scss" scoped>
.org-content {
  max-width: 800px;
  margin: 0 auto;
}
.org-header {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  border-bottom: 1px solid #000;
}
.company-header {
  padding: 8px 16px 8px 16px;
  background-color: var(--secondary);
  color: white;
  border-radius: 8px 8px 0 0;
  min-height: 49px;
  display: flex;
  align-items: center;
}
table {
  width: 100%;
  column-gap: 0;
  border-collapse: collapse;
  td {
    padding: 12px 16px;
  }
  thead {
    border-bottom: 1px solid #000;
  }
  tbody td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
}
.env-name {
  min-width: 280px;
}

.search-group {
  position: relative;
  width: 60%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.search-icon {
  position: absolute;
  height: 44px;
  padding-left: 48px;
  mask: url("/search-icon.svg");
  mask-position: center;
  mask-repeat: no-repeat;
  background-color: var(--gray-80);
}
input {
  width: 100%;
  padding: 10px 16px 10px 48px;
  border: 1px solid #cecece;
  background-color: transparent;
  border-radius: 45px;
  box-shadow: inset 0 0 0 0;
  box-sizing: border-box;
  &:focus {
    outline: #ee4c72 solid 2px;
  }
  &:focus ~ .search-icon {
    background-color: #ee4c72;
  }
  &.email-input {
    outline: unset;
    padding: 10px 16px 10px 0;
    border: 0;
    border-bottom: 1px solid #000000;
    border-radius: 0;
  }
}
.no-border {
  border: unset;
}
.counters {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>
